.attachmentItem{
  // width: 64px;
  // height: 64px;

  margin-right: 10px;
  overflow: hidden;
  // img{
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  // }
}

.previewItem {
  position: relative;
  display: inline-block;
}

.deleteIcon {
position: absolute;
top: -12px;
left: 32px;
color: red;
cursor: pointer;
z-index: 10;
}

.imageDisplay {
width: 50px;
object-fit: contain;
padding: 1px;
border: 1px solid black;
border-radius: 3px;

img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
}

.imageDisplay2 {
width: 55px;
height: 31px;
margin-bottom:20px;
object-fit: contain;

img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
}