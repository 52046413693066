@mixin spacings {
  @for $i from 0 through $marginsTo {
    .m-#{$i} {
      margin: #{$i}px !important;
    }
    .mv-#{$i} {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }
    .mh-#{$i} {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }
    .mt-#{$i} {
      margin-top: #{$i}px !important;
    }
    .mr-#{$i} {
      margin-right: #{$i}px !important;
    }
    .ml-#{$i} {
      margin-left: #{$i}px !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i}px !important;
    }
    .p-#{$i} {
      padding: #{$i}px !important;
    }
    .pv-#{$i} {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }
    .ph-#{$i} {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
    .pt-#{$i} {
      padding-top: #{$i}px !important;
    }
    .pr-#{$i} {
      padding-right: #{$i}px !important;
    }
    .pl-#{$i} {
      padding-left: #{$i}px !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i}px !important;
    }
  }
}
@mixin spacingsAuto {
  .m-auto {
    margin: auto !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .mr-auto {
    margin-right: auto !important;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .p-auto {
    padding: auto !important;
  }
  .pt-auto {
    padding-top: auto !important;
  }
  .pr-auto {
    padding-right: auto !important;
  }
  .pl-auto {
    padding-left: auto !important;
  }
  .pb-auto {
    padding-bottom: auto !important;
  }
}
@mixin fontsizes {
  @for $i from 0 through $fontsizeTo {
    .fs-#{$i} {
      font-size: #{$i}px !important;
    }
  }
}

@include spacings;
@include spacingsAuto;
@include fontsizes;

.responc {
  margin-bottom: 50px !important;
  background: #f7f7f7;
  padding: 20px;
  border: solid 1px #e1e1e1;
  box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
  -webkit-box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
  -moz-box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
}
.responc1 {
  margin-bottom: 10px !important;
  background: #f7f7f7;
  padding: 20px;
  border: solid 1px #e1e1e1;
  box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
  -webkit-box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
  -moz-box-shadow: 4px 12px 5px -6px rgba(219, 204, 204, 0.75);
}
.formtxt {
  margin-top: 50px;
  margin-bottom: 10px;
  color: #2d2d2d;
  font-weight: 600;
}
.formtxtbtm {
  margin-bottom: 50px !important;
}
.mainresp {
  border: solid 1px #cbcbcb;
  padding: 30px;
  border-radius: 5px;
  margin-top: 30px;
  background: #f9f9f9;
  overflow: auto;
}
.leftc {
  width: 50%;
  float: left;
  color: #474e5a;
}
.leftc span,
.rightc span {
  color: #414855;
  font-weight: 600;
  min-width: 150px;
  display: block;
  float: left;
}
.rightc {
  width: 40%;
  float: left;
  color: #474e5a;
  margin-left: 10px;
}
.textl {
  margin-bottom: 30px;
  @media screen and (max-width:900px) {
    width: 50%;
  }
}
.deissue1 .MuiToolbar-root {
  border: none !important;
}
.deissue .MuiOutlinedInput-root {
  -moz-box-shadow: inset 3px 3px 25px #c7c7c7;
  -webkit-box-shadow: inset 3px 3px 25px #c7c7c7;
  box-shadow: inset 3px 3px 25px #c7c7c7;
}
