$primary: #078037; //#4fc3f7;
$alert: #ffbc35;
$danger: #f62d51;
$secondary: #36bea6;
$fontColor: #000000;
$black: #000000;
$bodyBg: #eef5f9;
$badgeBg: #a42c35;
$wrap-bg: #ffffff;
$white: #ffffff;
$gray-bg: #ced4da;
$gray-color: #9699a4;
$gray-dark-color: #565656;
$gray-light-color: #9699a488;
$grayish-white: #dfdfdf;
$blue-color: #06b4fe;
$blue-dark-color: hsl(219, 100%, 50%);
$orange-color: #f97703;
/* var fof mixins */
$fontsizeTo: 30;
$hierarchylavelsTo: 10;
$hierarchylavelTo: 10;
$marginsTo: 40;
$spacingsSelectors: m, mt, mr, mb, ml, p, pt, pr, pb, pl;

$xxxxs: 2px;
$xxxs: 3px;
$xxs: 4px;
$xs: 6px;
$sm: 8px;
$md: 10px;
