html body {
  padding: 0;
  background-color: $bodyBg;
  font-family: "Raleway", "MarkOT", sans-serif;
  font-weight: normal;
  line-height: 16px;
  color: $fontColor;
  font-size: 14px;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

.breadcrumbs {
  a:not(.button) {
    text-decoration: none;
    color: #000;

    &:hover,
    &:focus,
    &.active,
    &:active {
      color: $primary;
    }
  }
}

a:not(.button) {
  text-decoration: none;
  color: #fff;

  &:hover,
  &:focus,
  &.active,
  &:active {
    color: $primary;
  }
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.content-wrap {
  min-height: calc(100vh - 133px);
}

.MuiContainer-root {
  @media (min-width:771px) and (max-width:932px) {
    .MuiContainer-root {
      width: 92%;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width:768px) {
  .MuiContainer-root{
    padding: 0 !important;
    margin: 0 !important;
  }
}

body {

  .color-danger {
    color: $danger !important;
  }

  .d-flex {
    display: flex;
  }

  .flex-end{
    align-items: flex-end;
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .flex-space-around {
    justify-content: space-around;
  }

  .flex-justify-center {
    justify-content: center;
  }

  .flex-justify-end {
    justify-content: end;
  }

  .flex-direction-row {
    flex-direction: row;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .mw-auto {
    min-width: auto !important;
  }

  .w-60 {
    min-width: 60px;
  }

  .border-bottom {
    border-bottom: 1px solid $primary;
  }

  .border-right {
    border-right: 1px solid $primary;
  }

  .border-top {
    border-top: 1px solid $primary;
  }

  .border-left {
    border-left: 1px solid $primary;
  }

  .MuiButton-root {
    text-transform: none !important;
  }

  .MuiIconButton-root:hover {
    background-color: initial;
  }

  .w-100 {
    width: 100%;
  }

  .w-40 {
    width: 40%;
  }

  .w-50 {
    width: 50%;
  }

  .w-30 {
    width: 30%;
  }

  .h-stretch {
    height: 100%;
  }

  .h-stretch8 {
    height: 90%;
  }

  .h-stretch9 {
    height: 94%;
  }

  .fullwidth {
    display: block;
    width: 100%;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .fleft {
    float: left;
  }

  .fright {
    float: right;
  }

  .fw-bold {
    font-weight: bold;
  }

  .strong {
    font-weight: 500;
  }

  .scrollable {
    max-height: 210px;
    overflow-y: auto;
  }
}

.MuiTypography-h5 {
  @media screen and (max-width:940px) {
  color: #3d3d3d !important;
  }
  font-size: 1.2rem !important;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600 !important;
  line-height: 1.334;
}

.MuiTypography-body1 {
  @media screen and (max-width:940px) {
    color: #3d3d3d !important;
  }
  font-size: 1rem !important;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500 !important;
  line-height: 1.5;
}

.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 420 !important;
  line-height: 1.43;
}

tr.border-class td {
  border-top: 2px solid #e0e0e0 !important;
}

.noBorder tbody tr {
  border: none;
}

@media screen and (max-width:940px) {
  .MuiCardContent-root {
    padding: 0 !important;
  }
}

.badge {
  background: $badgeBg;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 5px;
  border-radius: 4px;
  vertical-align: text-top;
  text-transform: none;
  display: inline-block;
}

.button {
  background-color: $primary;
  color: #ffffff;
  box-shadow: none;
  border: none;
}

button.flatbutton {
  box-shadow: none;
  border: none;
  border-radius: 0;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  &.w-norm {
    width: 200px;

    @media screen and (max-width:768px) {
      width: 100%;
    }
  }

  @media (max-width: 431px) {
    height: 31px;
  }
}

button.button-danger {
  background: $danger;
  color: #ffffff;
  @media screen and (max-width:940px) {
    padding: 15px;
  }
}

button.linkbutton {
  background: transparent;
  color: $blue-color;
  padding: 0;
  text-decoration: underline;
}

.emailRes {
  @media screen and (max-width:768px) {
    margin-left: -55px !important;
  }
}

.menulist {

  &,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    position: relative;
    padding: 0 0px;

    @media screen and (max-width: 600px) {
      display: block;
    }

    .menulink.MuiButton-root {
      min-width: auto;
    }

    .menulink {
      padding: 0;
      background: none;
      text-transform: none;
    }

    &.active {

      &,
      a {
        color: $primary;
      }
    }

    &:not(:first-child):after {
      content: "";
      position: absolute;
      left: 0;
      height: 12px;
      background: #fff;
      width: 1px;
      top: 0;
      bottom: 0;
      margin: auto;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  &.footermenu li a {
    font-size: 12px;
    color: $secondary;
  }
}

.containerSpacing {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loaderCSS {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.sectionBox {
  background-color: $wrap-bg;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;

  @media screen and (max-width:940px) {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
  }

  &.nospacing {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.pad_0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.radius {
    border-radius: 4px;
  }

  &.shadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
}

.clear {
  clear: both;
  display: block;

  &:before,
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

.formFieldGroup {

  label,
  input {
    padding: 0 12px;
  }

  .input {
    border: 1px solid #e9ecf0;
    border-radius: 4px;
    box-shadow: none;
    padding: 0;
    margin: 5px 0;

    &:before {
      display: none;
    }

    input {
      padding: 6px 12px 7px;
      margin: 0;
    }
  }
}

.BlankContainer,
.DefaultContainer {
  min-height: 250px;
}

.loginwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 355px;
  max-width: 90%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  padding: 15px 20px;
  overflow-y: auto;
  max-height: 94%;

  .cmslogo {
    padding: 0 15px;
  }

  @media (max-height: 350px) {
    transform: translate(-50%, 0);
    top: 0;
  }

  @media (max-width: 350px) {
    transform: translate(0, -50%);
    left: 0;
  }

  @media (max-height: 350px) and (max-width: 350px) {
    transform: translate(0, 0);
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
  }
}

.loginbottom {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.input {
  border: 1px solid #e9ecf0;
  border-radius: 4px;
  box-shadow: none;

  &:before {
    display: none;
  }

  input {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
  }

  .input-adornment {
    background: #f8f9fa;
    border: none;
    border-right: 1px solid #e9ecf0;
    padding: 11px 9px;
    line-height: 0;
    display: block;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 38px;
  }

  .MuiInputAdornment-root {
    background: #f8f9fa;
    height: 100%;
    border: none;
    border-right: 1px solid #e9ecf0;
    padding: 5px 5px 5px 5px;
    line-height: 1;
    display: block;
    position: relative;
    top: 0;
    bottom: 0;
  }
}

.select-input select {
  padding: 10px 12px;
}

.usersWrap {
  display: flex;
  align-items: center;
  padding: 20px 15px 10px;

  .userImg {
    margin-right: 15px;
    line-height: 0;
  }

  .userInfo {
    cursor: pointer;

    .name {
      text-transform: capitalize;
      font-size: 14px;
      font-family: "Raleway";
      font-weight: 600;
      text-align: right;
    }

    .MuiTypography-caption {
      font-size: 12px;
      color: $gray-color;

      @media screen and (max-width:400px) {
        font-size: 7px;
      }
    }
  }

  svg {
    vertical-align: middle;
  }
}

.userMenuWrap {
  width: 140px;

  li {
    padding: 0;
    text-align: left;
    font-size: 14px;

    a {
      display: block;
      padding: 10px 15px;

      &:hover {
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
      }
    }
  }
}

.svg-icon {
  vertical-align: middle;
}

.MuiMenu-list {
  background-color: #fff;
}

ul.sidebarMenuWrap {
  // overflow-x: auto;
  // max-height: calc(100% - 135px);
  // padding-left: 13px;
  // padding-right: 12px;
  padding-bottom: 30px;
  border-top: 1px Solid #ffffff22;

  &>li {
    margin-bottom: 10px;
  }

  li {
    padding: 0;
    display: block;

    a {
      padding: 10px 15px;
      display: flex;
      font-weight: 600;
      align-items: center;
      width: 100%;

      svg {
        margin-right: 10px;
        color: #fff;
      }

      &.button svg {
        color: #ffffff;
      }

      &.active:not(.button) {
        color: $primary;

        svg {
          color: $primary;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
      }
    }

    .submenuitems {
      display: block;
      width: 100%;
      // padding-left: 35px;
      padding-top: 0;
      padding-bottom: 0;

      li>a {
        padding: 5px 15px;
        display: block;
        position: relative;
        width: 100%;
        font-weight: 400;
        padding-left: 50px;

        &.active {
          color: $primary;
        }

        &:hover {
          background: $gray-light-color;
          color: $white;
        }

        // &::before {
        //   position: absolute;
        //   left: -3px;
        //   top: 0;
        //   bottom: 0;
        //   height: 1px;
        //   width: 4px;
        //   content: "";
        //   display: block;
        //   margin: auto;
        //   background: #fff;
        // }
      }
    }
  }

  .innerChildItemTitle {
    display: flex;

    >a {
      padding-right: 5px;
      padding-left: 5px;
      position: relative;
      // &::before {
      //   position: absolute;
      //   left: -3px;
      //   top: 0;
      //   bottom: 0;
      //   height: 1px;
      //   width: 4px;
      //   content: "";
      //   display: block;
      //   margin: auto;
      //   background: #000;
      // }
    }
  }
}

.file-uploader {
  display: flex;

  button {
    margin-left: 12px;
  }
}

.issuesPadding input{
  padding: 8px !important;
}

.issuesPadding{
  max-width: 50% !important;
}


.logoImgPreview {
  max-width: 230px;
  margin: 2px 0;
}

.themebadge {
  padding: 5px 12px;
  background: $primary;
  margin: 10px 3px 0;
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
}

.icbutton {
  svg {
    display: block;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }
}

.circularprogress {
  .actual .MuiCircularProgress-svg {
    transform: rotate(180deg);
  }

  &.color-warning {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $alert;
    }
  }

  &.color-danger {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $danger;
    }
  }

  &.color-success {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $primary;
    }
  }

  &.color-blue {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $blue-dark-color;
    }
  }

  &.color-bluese {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $secondary;
    }
  }

  & .MuiTypography-root {
    font-weight: bold;
  }
}

.ulhide li:nth-child(n + 3) {
  border-bottom: none !important;
}

.ulhide li:nth-child(n + 4) {
  display: none;
}
.issuelink {
  color: #5d6ff5 !important;
  padding: 10px 0 !important;
  text-decoration: underline !important;
}
.cms-logo {
  display: block;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 20px;
  @media screen and (max-width:768px) {
    margin-right: 10px !important;
  }
}

.ulhideis li:nth-child(n + 4) {
  display: none;
}

.greencol {
  color: #078037;
  margin-right: 5px;

  width: 0.8em !important;
  height: 0.8em !important;
}

.ulhide>div:nth-child(n + 4) {
  display: none;
}

.position-relative {
  position: relative;
}

.circularprogress {
  .actual .MuiCircularProgress-svg {
    transform: rotate(180deg);
  }

  &.color-appts {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #2b6823;
    }
  }

  &.color-empre {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #f9bc0a;
    }
  }

  &.color-iss {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #2d7bf4;
    }
  }

  &.color-noti {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #21b792;
    }
  }
}

.tabletd .themeTable tr td .themeCellContent {
  padding: 5px 0px !important;
  font-size: 16px !important;
  text-transform: capitalize;
  color: #333841 !important;
}

/* table*/
.themeTable tbody tr:nth-child(even) {
  background: #efefef;
  margin-bottom: 12px !important;
}

.themeTable tbody tr:nth-child(odd) {
  background: #fff;
  margin-bottom: 12px !important;
}

.themeTable tbody tr:hover {
  background: #ddd;
}

tbody tr.transparent,
tbody tr.transparent:hover {
  background: transparent !important;
}

.themeTable>thead tr th.no-border {
  border-bottom: 0px solid rgba(00, 00, 00) !important;
  padding: 0px 5px;
}

.themeTable>thead tr th {
  border-bottom: 1px solid #dedede !important;
}

.profilet {
  font-size: 1.25rem;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  padding-bottom: 10px;
  line-height: 1.6;
  text-transform: uppercase;
  border-bottom: solid 1px #e9e9e9;
}

.themeTableContainer {
  overflow-x: auto;
}

.themeTable thead tr>th {
  background-color: #fff;
}

.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:first-child,
.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:last-child {
  background: #fff;
}

.themeTableContainer .themeTable tbody tr:nth-child(even)>td:first-child,
.themeTableContainer .themeTable tbody tr:nth-child(even)>td:last-child {
  background: #efefef;
}

.themeTableContainer tr>th:first-child,
.themeTableContainer tr>td:first-child {
  position: sticky;
  left: 0;
  border-right: 1px Solid #b9b9b9 !important;
}

.themeTableContainer th:nth-last-child(2),
.themeTableContainer td:nth-last-child(2) {
  position: sticky;
  right: 53px;
  border-left: 1px solid #b9b9b9 !important;
}

.themeTableContainer .themeTable tbody tr:nth-child(even)>td:nth-last-child(2) {
  background: #efefef;
}

.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:nth-last-child(2) {
  background: #fff;
}

.themeTableContainer tr>th:last-child,
.themeTableContainer tr>td:last-child {
  position: sticky;
  right: 0;
  border-left: 1px Solid #b9b9b9 !important;
}
